import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth } from 'firebase/auth';

var firebaseConfig = {
  // apiKey: "AIzaSyDGor0dVOq2bZBBojnrXl7Wy673mHEqL-Y",
  // authDomain: "dev-test-2860e.firebaseapp.com",
  // projectId: "dev-test-2860e",
  // storageBucket: "dev-test-2860e.appspot.com",
  // messagingSenderId: "469488545905",
  // appId: "1:469488545905:web:e827d5670564cbccb1abd0",
  // measurementId: "G-D7N6DWJ79R",

  apiKey: "AIzaSyDYjX4I4e_f61HOyZVRWi_VYBNHOZu45oE",
  authDomain: "leadsparrow-e4b97.firebaseapp.com",
  projectId: "leadsparrow-e4b97",
  storageBucket: "leadsparrow-e4b97.appspot.com",
  messagingSenderId: "446686360160",
  appId: "1:446686360160:web:9a12da3e8ba783ed758905",
  measurementId: "G-SEK38W6ZVT"

};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const auth = getAuth(firebaseApp);

export const fetchToken = (setTokenFound) => {
  //alert(setTokenFound)
  return getToken(messaging, {
    vapidKey:
      "BHGm3rbWYg9T74wRFFc46m-TR8U5brTzn73h2IhflPnexPOklA8f4RgnaYVBihGb2y828h8eGs5SiJUCVdUkdWc",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("firebase_token",currentToken)
        setTokenFound = true;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound = false;
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });




  
  export {auth}
