
/* eslint-disable */

import React, { Component, Suspense, lazy, useState } from "react";

import { connect } from "react-redux";
//import { Route, Router, BrowserRouter, Switch,Redirect,useHistory,withRouter,Link } from 'react-router-dom';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CloseIcon from "@material-ui/icons/Close";
import { fetchToken, onMessageListener } from "./firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment/moment";
require('dotenv').config();
import $ from "jquery";

/*import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.min.js";*/

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { login } from "./actions/auth";
import jwtDecode from "jwt-decode";
//import EnquiryForm from "./components/EnquiryForm";
//import SuperAdminDashboard from "./components/SuperAdmin/SuperAdminDashboard";
// import Drive from './components/admin/DriveList';
// import RoleList from './components/admin/RoleList';
//import $ from 'jquery';
//import Popper from 'popper.js';
import { AdminInfo } from "./actions/AdminLogin";
import { AdminLogout } from "./actions/AdminLogin";
/*import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/custom.css";
import "./App.css";*/

//import { clearMessage } from "./actions/message";

//import { history } from './helpers/history';

const Login = lazy(() => import("./components/Login"));
const UserList = lazy(() => import("./components/admin/UserList"));
const Dashboard = lazy(() => import("./components/admin/Dashboard"));
// const LeadList =lazy(() =>import ('./components/admin/LeadList'));
const ProductList = lazy(() => import("./components/admin/ProductList"));
const DepartmentList = lazy(() => import("./components/admin/DepartmentList"));
const Lead = lazy(() => import("./components/admin/LeadList"));
const FieldEngg = lazy(() => import("./components/admin/FieldEngg"));
const StaffListV1 = lazy(() => import("./components/admin/FieldEnggV1"));
const SlotList = lazy(() => import("./components/admin/SlotList"));
const SlotListV1 = lazy(() => import("./components/admin/SlotList_v1"));
const TemplateList = lazy(() => import("./components/admin/TemplateList"));
const RoleList = lazy(() => import("./components/admin/RoleList"));
const CampaignList = lazy(() => import("./components/admin/CampaignList"));
const DriveList = lazy(() => import("./components/admin/DriveList"));
const ReportList = lazy(() => import("./components/admin/ReportList"));
const ReportListAppointment = lazy(() => import("./components/admin/ReportListAppointment"));
const ProductRevenueReport = lazy(() => import("./components/admin/ProductRevenue"));
const DTMFList = lazy(() => import("./components/admin/DTMFList"));
const Notifications = lazy(() => import("./components/admin/Notifications"))
const SuperAdminLogin = lazy(() => import("./components/SuperAdminLogin"))
const SuperAdminSideBar = lazy(() => import("./components/SideBarSuperAdmin"))
const AdminList = lazy(() => import("./components/SuperAdmin/AdminList"))
const SuperAdminDashboard = lazy(() => import("./components/SuperAdmin/SuperAdminDashboard"))
const TemplateListOfficialwhatsapp = lazy(() => import("./components/admin/TemplateListOfficalWhatsapp"))
const EnquiryLink = lazy(() => import("./components/EnquiryForm"))
const ConfirmEnquiryForm = lazy(() => import("./components/ConfirmEnquiryForm"))

// const LeadS=lazy(()=>import('./components/admin/Lead'));

/*const Index =lazy(() =>import ('./components/Index'));
const Aboutus =lazy(() =>import ('./components/Aboutus'));
const TermsCondition =lazy(() =>import ('./components/TermsCondition'));
const PrivacyPolicy =lazy(() =>import ('./components/PrivacyPolicy'));
const Faqs =lazy(() =>import ('./components/Faqs'));
const HowItWorks =lazy(() =>import ('./components/HowItWorks'));
const AboutPartners =lazy(() =>import ('./components/AboutPartners'));
const aboutTeam =lazy(() =>import ('./components/aboutTeam'));
const Contact =lazy(() =>import ('./components/Contact'));

const PostInfo =lazy(() =>import ('./components/PostInfoPage'));

const NgoRegProcOne =lazy(() =>import ('./components/ngo/NgoRegProcOne'));
const NgoRegProcTwo =lazy(() =>import ('./components/ngo/NgoRegProcTwo'));
const NgoRegProcThree =lazy(() =>import ('./components/ngo/NgoRegProcThree'));
const NgoRegProcFour =lazy(() =>import ('./components/ngo/NgoRegProcFour'));
const NgoRegProcThankYou =lazy(() =>import ('./components/ngo/NgoRegProcThankYou'));
const NgoRegProcOneUpdate =lazy(() =>import ('./components/ngo/NgoRegProcOneUpdate'));
const NgoRegProcTwoUpdate =lazy(() =>import ('./components/ngo/NgoRegProcTwoUpdate'));
const NgoRegProcThreeUpdate =lazy(() =>import ('./components/ngo/NgoRegProcThreeUpdate'));
const NgoRegProcFourUpdate =lazy(() =>import ('./components/ngo/NgoRegProcFourUpdate'));

const NgoRegProgThankYou =lazy(() =>import ('./components/ngo/NgoRegProgressThankYou'));

const NgoHome =lazy(() =>import ('./components/ngo/NgoHome'));

const UserHome =lazy(() =>import ('./components/user/UserHome'));
const UserProfile =lazy(() =>import ('./components/user/UserProfile'));
const UserUserProfile =lazy(() =>import ('./components/user/UserUserProfile'));

const UserNgoList =lazy(() =>import ('./components/user/UserNgoList'));
//const UserList =lazy(() =>import ('./components/user/UserList'));
const UserProfile1 =lazy(() =>import ('./components/user/UserProfile1'));
//const UserVolunteerList =lazy(() =>import ('./components/user/UserVolunteerList'));
const UserNgoReview =lazy(() =>import ('./components/user/UserNgoReview'));
const UserNgoInfo =lazy(() =>import ('./components/user/UserNgoInfo'));
const NgoReviewList =lazy(() =>import ('./components/ngo/NgoReviewList'));
const NgoAllValunteer =lazy(() =>import ('./components/ngo/NgoAllValunteer'));
//const NgoInterestedValunteer =lazy(() =>import ('./components/ngo/NgoAllInterestedValunteer'));
//const NgoValunteerListing =lazy(() =>import ('./components/ngo/NgoAllValunteerListing'));
const NgoProfile =lazy(() =>import ('./components/ngo/NgoProfilePage'));
const NgoAboutProfile =lazy(() =>import ('./components/ngo/NgoAboutProfile'));
const NgoOrganization =lazy(() =>import ('./components/ngo/NgoOrganization'));
const NgoDonation =lazy(() =>import ('./components/ngo/NgoDonation'));
//const NgoDoGoodListing =lazy(() =>import ('./components/ngo/NgoDoGoodListing'));
const NgoNotification =lazy(() =>import ('./components/ngo/NgoNotification'));
const NgoAboutProfile1 =lazy(() =>import ('./components/ngo/NgoAboutProfile1'));

const UserDonate =lazy(() =>import ('./components/user/UserDonate'));
const UserValunteer =lazy(() =>import ('./components/user/UserValunteer'));
const UserAboutProfile =lazy(() =>import ('./components/user/UserAboutProfile'));

const UserMyCampaign =lazy(() =>import ('./components/user/UserMyCampaign'));
const UserMyDonations =lazy(() =>import ('./components/user/UserMyDonations'));

const Volunteer =lazy(() =>import ('./components/Valunteer'));
const Donate =lazy(() =>import ('./components/Donate'));
const NgoList =lazy(() =>import ('./components/NgoList'));

const UserNgoProfile =lazy(() =>import ('./components/user/NgoProfilePage'));
const UserNgoAboutProfile =lazy(() =>import ('./components/user/NgoAboutProfile'));

const UserNotification =lazy(() =>import ('./components/user/UserNotification'));*/

toast.configure();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTokenFound: false,
      isloading: false,
      isTokenForsuperAdmin: false,
      lastinactive_time: new Date(),
      status:""
    };
  }

  componentDidMount() {
    this.setState({
      firebasetoken:localStorage.getItem("firebase_token")
    })
    this.AdminInfoFn();

    // let tok=localStorage.getItem("token")
    // // // console.log(localStorage.getItem("token"))

    // if (tok&& typeof tok!=="undefined") {
    //   console.log("helooooooooo");


    //   console.log("port in app ",process.env.PORT)
    // }
    
    let token = localStorage.getItem("token")
    //   alert(token)
    if (token && token !== "" && typeof token !== "undefined") {
      let usercode = jwtDecode(token)
      console.log(usercode);
      // this.setState({
      //   lastinactive_time: new Date()
      // })
      // const inactivityTimeout = 2 * 60 * 60 * 1000;
      // // const inactivityTimeout = 120000;
   
      // let timer= setTimeout(() => {
      //   let currentTime=new Date()
      // console.log(this.state.lastinactive_time);
      // console.log(currentTime);
      //   if (currentTime - this.state.lastinactive_time >= inactivityTimeout) {
      //   //  $("#subscriptionExpire").modal("show")
      //   this.LogOut();
      //   }
      // }, inactivityTimeout);
      
      console.log(moment(usercode.iat).format("YYYY-MM-DD HH:mm:SS"));
      console.log(moment(usercode.exp).format("YYYY-MM-DD HH:mm:SS"));
      console.log(moment().format("YYYY-MM-DD HH:mm:SS"));
      var expdate = moment(usercode.exp).format("YYYY-MM-DD HH:mm:SS")
      
      // var date = new Date();
      // var day = date.getDate();
      // var month = date.getMonth() + 1;
      // var year = date.getFullYear();
      //moment(this.state.start_date).add(1, 'day').format("YYYY-MM-DD") 
      var date=new Date(usercode.exp)
     // alert(date)
      var minute=date.setMinutes(date.getMinutes()-2); 
      console.log(moment(expdate).add(2, "minutes").format("YYYY-MM-DD HH:mm:ss"))
      console.log(moment(minute).format("YYYY-MM-DD HH:mm:SS"))
     setInterval(()=>{
      if(moment(minute).format("YYYY-MM-DD HH:mm:SS")==moment().format("YYYY-MM-DD HH:mm:SS")){
        $("#subscriptionExpire").modal("show")
      }
     },1000)
     
    //  var currdate = year + "-" + month + "-" + day;
      //  var currdate = moment().format("hh:mm:ss")
      console.log(expdate <= moment().format("YYYY-MM-DD HH:mm:ss"));
      if (expdate <= moment().format("YYYY-MM-DD HH:mm:ss")) {
     //   console.log("hello2");
     this.LogOut();
       // $("#subscriptionExpire").modal("show")
      }
      
      this.setState({
        isTokenFound: true
      })
    //   console.log(expdate <= moment().format("YYYY-MM-DD HH:MM:SS"));
    //   if (expdate < moment().format("YYYY-MM-DD HH:MM:SS")) {
    //  //   console.log("hello2");
    //  this.LogOut();
    //    // $("#subscriptionExpire").modal("show")
    //   }
      
    //   this.setState({
    //     isTokenFound: true
    //   })



    }
    let token1 = localStorage.getItem("token1")
    //alert(localStorage.getItem("token1"))
    if (token1 && token1 !== "" && typeof token1 !== "undefined") {
      this.setState({
        isTokenForsuperAdmin: true
      })
    }
  //  alert(this.state.isTokenFound);
    fetchToken(this.state.isTokenFound);



    //fetchToken(this.state.isTokenForsuperAdmin);
    // onMessageListener()
    //   .then((payload) => {
    //     var dateString = moment
    //       .unix(payload.data["google.c.a.ts"])
    //       .format("hh:mm A");
    //     toast.dark(
    //       <div className="notification-toast-wrap">
    //         <div className="nt-time">
    //           <span>{dateString}</span>
    //         </div>
    //         <div className="nt-icon">
    //           <div className="nti-inner">
    //             <NotificationsActiveIcon />
    //           </div>
    //         </div>
    //         <div className="nt-title">{payload.notification.title}</div>
    //         <div className="nt-body">{payload.notification.body}</div>
    //       </div>,
    //       {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeonClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       }
    //     );
    //     console.log("payload", payload);
    //   })
    //   .catch((err) => console.log("failed: ", err));
  }
  //constructor(props) {
  //super(props);

  /*this.state = {
      currentUser: undefined,
    };*/

  /*history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });*/
  //}

  //componentDidMount() {
  //const user = this.props.user;

  /*if (user) {
      this.setState({
        currentUser: user,
      });
    }*/
  //}
  // LogOut = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("usersss");
  //   window.location.href = "/"
  // }

  AdminInfoFn = () => {
    const { dispatch, history } = this.props;
    if (localStorage.getItem("usersss")) {
      let username = JSON.parse(localStorage.getItem("usersss")).username

      dispatch(AdminInfo(username))
        .then((response) => {
          console.log("user");
          console.log(response.data);
          this.setState({
            //email_id_to_send_mail:response.data[0].email_id_to_send_mail,
            official_whatsapp: response.data[0].official_whatsapp,
            status:response.data[0].status,
            username:response.data[0].username,
            // logo:response.data[0].logo,
            // isFacebookApproved:response.data[0].isFacebookApproved,
            // isEmailApproved:response.data[0].isEmailApproved,
            // isIRVApproved:response.data[0].isIRVApproved,
            // isEnquiryApproved:response.data[0].isEnquiryApproved,
            // isIndiamartApproved:response.data[0].isIndiamartApproved,
            // isWhatsappApproved:response.data[0].isWhatsappApproved,
            // startDate:response.data[0].startDate,
            // endDate:response.data[0].endDate,
            // subscription_type:response.data[0].subscription_type,
            // enquirylink:"https://crm.leadsparrows.com/enquiry/"+response.data[0].name+"/"+token+"/"+response.data[0].official_whatsapp+"/"+response.data[0].logo
            // verifyToken: response.data,
          },()=>{if (this.state.status!==1) {
            console.log("hello1");
            $("#DeactivedAdmin").modal("show")
          }});
          // this.TableDataUpdate();
        })
        .catch(() => {
          this.setState({

          });
        });
      // console.log("indiamart_key"+(JSON.parse(localStorage.getItem("usersss"))).indiamart_key);
    }
  };

  LogOut = () => {
  
   const { dispatch, history } = this.props;
    if (localStorage.getItem("usersss")) {
      let username = JSON.parse(localStorage.getItem("usersss")).username
      let firebasetoken = localStorage.getItem("firebase_token")
      console.log(username);
      dispatch(AdminLogout(username,firebasetoken))
      .then((response) => {
       

        if (
          response.success ||
          response.success === "true" ||
          response.success === true
        ) {
          // localStorage.setItem("firebase_token",currentToken)
          window.location.href = "/"
          toast.success(response.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // this.ListTemplateFun(
          //   this.state.template_msg,
          //   Number(this.state.template_id)
          // );

      
        } else {
          this.setState({
            loading: false
          })
          
          toast.error(response.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        localStorage.removeItem("token");
        localStorage.removeItem("usersss");
       localStorage.removeItem("firebase_token");
      window.location.href = "/"
      })
      .catch((error) => {
        console.log("error++", error)
        this.setState({
          loading: false,
        });

        toast.error("something went wrong..!!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
      // console.log("indiamart_key"+(JSON.parse(localStorage.getItem("usersss"))).indiamart_key);
    }
  };


  render() {
    //const { currentUser } = this.state;

    return (
      <React.Fragment>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Switch>
            //without login pages
                  
              <Route exact path="/enquiry/:user/:token/:whatsapp/:logo" component={EnquiryLink} />
              <Route exact path="/ConfirmAppointment/:id/:user" component={ConfirmEnquiryForm} />

              <Route exact path="/superadminlogin" component={SuperAdminLogin} />
              {/* {(() => {
              if (!this.state.isTokenForsuperAdmin) {
                return (
                  <>
          
             <Route exact path="/superadmin/sidebar" component={SuperAdminLogin}/>
            <Route exact path="/superadmin/admin/list" component={SuperAdminLogin}/>
            <Route exact path="/superadmin/Dahboard" component={SuperAdminLogin}/>
            </>)}
            })()} */}
              {/* {(() => {
              if (this.state.isTokenForsuperAdmin) {
                return (
                  <> */}

              <Route exact path="/superadmin/sidebar" component={SuperAdminSideBar} />
              <Route exact path="/superadmin/admin/list" component={AdminList} />
              <Route exact path="/superadmin/Dashboard" component={SuperAdminDashboard} />
              {/* </>)}
            })()}  */}

              {/* if not logged in then redirect to login page  */}
              {(() => {
                if (!this.state.isTokenFound) {

                  return (
                    <>
                      <Route exact path="/" component={Login} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/admin/dashboard" component={Login} />
                      <Route exact path="/admin/user/list" component={Login} />

                      <Route exact path="/admin/product/list" component={Login} />
                      <Route
                        exact
                        path="/admin/department/list"
                        component={Login}
                      />
                      <Route exact path="/admin/Leadlist" component={Login} />
                      <Route exact path="/admin/staff/list" component={Login} />
                      <Route exact path="/admin/slot/list" component={Login} />
                      <Route exact path="/admin/template/list" component={Login} />
                      <Route exact path="/admin/role/list" component={Login} />
                      <Route exact path="/admin/campaign/list" component={Login} />
                      <Route exact path="/admin/drive/list" component={Login} />
                      <Route exact path="/admin/report/list" component={Login} />
                      <Route exact path="/admin/appointment/report/list" component={Login} />
                      <Route exact path="/admin/DTMF/list" component={Login} />
                      <Route exact path="/admin/notification/list" component={Login} />
                    </>
                  )
                }
              })()}
              {/*------------ ----------------------------------------------------------------------- */}

              {(() => {
                console.log("this.state.isTokenFound" + this.state.isTokenFound);
                if (this.state.isTokenFound) {
                  return (
                    <>
                    
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/login" component={Dashboard} />
                      <Route exact path="/admin/dashboard" component={Dashboard} />
                      <Route exact path="/admin/user/list" component={UserList} />
                      {/* <Route exact path="/admin/lead/list" component={LeadList} /> */}
                      <Route exact path="/admin/product/list" component={ProductList} />
                      <Route
                        exact
                        path="/admin/department/list"
                        component={DepartmentList}
                      />
                      <Route exact path="/admin/Leadlist" component={Lead} />
                      <Route exact path="/admin/staff/list" component={FieldEngg} />
                      {JSON.parse(localStorage.getItem("usersss")) &&
                        <>
                          {(JSON.parse(localStorage.getItem("usersss")).ApiVersion == "v2") &&
                            <>
                              <Route exact path="/admin/slot/list/v2" component={SlotListV1} />
                              <Route exact path="/admin/staff/list/v2" component={StaffListV1} />
                            </>
                          }
                        </>}
                      <Route exact path="/admin/slot/list" component={SlotList} />


                      {this.state.official_whatsapp == 1 &&

                        <Route exact path="/admin/template/list" component={TemplateListOfficialwhatsapp} />
                      }
                      {(this.state.official_whatsapp == 0 || this.state.official_whatsapp == 2 )&&
                        <Route exact path="/admin/template/list" component={TemplateList} />
                      }






                      <Route exact path="/admin/role/list" component={RoleList} />
                      <Route exact path="/admin/campaign/list" component={CampaignList} />
                      <Route exact path="/admin/drive/list" component={DriveList} />
                      <Route exact path="/admin/report/list" component={ReportList} />
                      <Route exact path="/admin/appointment/report/list" component={ReportListAppointment} />
                      <Route exact path="/admin/ProductRevenue/Report/list" component={ProductRevenueReport} />
                      <Route exact path="/admin/DTMF/list" component={DTMFList} />
                      <Route exact path="/admin/notification/list" component={Notifications} />
                    </>
                  )
                }
              })()}


              {/* <Route exact path="/admin/lead" component={LeadS}/> */}
              {/*<Route exact path="/volunteer" component={Volunteer} />
            <Route exact path="/donate" component={Donate} />
            <Route exact path="/ngolist" component={NgoList} />

            <Route exact path="/post/info/:post_id" component={PostInfo} />

            //static page
            <Route exact path="/aboutus" component={Aboutus} />
            <Route exact path="/termscondition" component={TermsCondition} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/faqs" component={Faqs} />
            <Route exact path="/howitworks" component={HowItWorks} />
            <Route exact path="/aboutpartners" component={AboutPartners} />
            <Route exact path="/aboutteam" component={aboutTeam} />
            <Route exact path="/contact" component={Contact} />

            //ngo register
            <Route exact path="/ngo/registration/one" component={NgoRegProcOne} />
            <Route exact path="/ngo/registration/two" component={NgoRegProcTwo} />
            <Route exact path="/ngo/registration/three" component={NgoRegProcThree} />
            <Route exact path="/ngo/registration/four" component={NgoRegProcFour} />
            <Route exact path="/ngo/registration/thankyou" component={NgoRegProcThankYou} />
			
			      <Route exact path="/ngo/registration/updateone/:ngo_id" component={NgoRegProcOneUpdate} />
            <Route exact path="/ngo/registration/updatetwo/:ngo_id" component={NgoRegProcTwoUpdate} />
            <Route exact path="/ngo/registration/updatethree/:ngo_id" component={NgoRegProcThreeUpdate} />
            <Route exact path="/ngo/registration/updatefour/:ngo_id" component={NgoRegProcFourUpdate} />
            <Route exact path="/ngo/progress" component={NgoRegProgThankYou} />
            
            //ngo
            <Route exact path="/ngo/home" component={NgoHome} />
            <Route exact path="/ngo/reviewlist" component={NgoReviewList} />
            <Route exact path="/ngo/ngoallvalunteer" component={NgoAllValunteer} />
            {/*<Route exact path="/ngo/ngointerestedvalunteer" component={NgoInterestedValunteer} />
            <Route exact path="/ngo/ngovalunteerlisting" component={NgoValunteerListing} />*/}
              {/*<Route exact path="/ngo/profile" component={NgoProfile} />
            <Route exact path="/ngo/about_profile" component={NgoAboutProfile} />
            <Route exact path="/ngo/organisational_details" component={NgoOrganization} />
           
            <Route exact path="/ngo/ngoAboutProfile1" component={ NgoAboutProfile1} /> 
            <Route exact path="/ngo/donations" component={NgoDonation} />
            {/*<Route exact path="/ngo/dogoodlisting" component={NgoDoGoodListing} />*/}
              {/*<Route exact path="/ngo/notification" component={NgoNotification} />

            /*<Route exact path="/ngo/post/info/:post_id" component={PostInfo} />*/}
              {/*<Route exact path="/user/home" component={UserHome} />
            <Route exact path="/user/profile" component={UserProfile} />
            <Route exact path="/user/info/:user_id" component={UserUserProfile} />
            <Route exact path="/user/ngo/list" component={UserNgoList} />
            <Route exact path="/user/ngo/info/:ngo_id" component={UserNgoInfo} />
            <Route exact path="/user/user/list" component={UserList} />
            {/*<Route exact path="/user/donate/list" component={UserDonateList} />
            <Route exact path="/user/volunteer/list" component={UserVolunteerList} />*/}
              {/*<Route exact path="/user/ngo/review/:ngo_id" component={UserNgoReview} />
            <Route exact path="/user/donate" component={UserDonate} />
            <Route exact path="/user/volunteer" component={UserValunteer} />
            <Route exact path="/user/about_profile" component={UserAboutProfile} />
            <Route exact path="/user/user_my_campaign" component={UserMyCampaign} />
            <Route exact path="/user/user_my_donations" component={UserMyDonations} />
            <Route exact path="/user/ngo/profile/:ngo_id" component={UserNgoProfile} />
            <Route exact path="/user/ngo/about_profile/:ngo_id" component={UserNgoAboutProfile} />
            <Route exact path="/user/notification" component={UserNotification} />
            <Route exact path="/user/userprofile1" component={UserProfile1} />*/}
              {/*<Route exact path="/user/post/info/:post_id" component={PostInfo} />*/}
            </Switch>
          </Suspense>
        </BrowserRouter>
        <div
          className="modal fade"
          id="subscriptionExpire"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm ">
            <div className="modal-content pt-0 pb-2 px-3">
              {/* <div className="modal-header border-0 pb-0">
                    <button type="button" className="close" data-dismiss="modal"><img src={closeicon} width="20px" height="20px"/></button>
                </div> */}
              <div className="container">
                {/* <Form
                onSubmit={this.handlengoPostSubmit}
                ref={(c) => {
                  this.form = c;
                }}> */}

                <div className="row ">
                  <div className="col-md-12 col-12 pl-md-0 position-relative">
                    <div className="row">


                      <div className=" pb-2 pt-3">
                        <h3 className="text-center">
                        Hi! please save your all work, as within 2 minutes your session will expire. 
                        </h3>
                      </div>

                      <div className="col-12  pb-2 pt-3">

                        <div className="display-center">
                          <button
                            className="btn btn-primary ok-btn"
                            data-dismiss="modal"
                         //   onClick={this.LogOut}
                          >
                            Ok
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="DeactivedAdmin"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm ">
            <div className="modal-content pt-0 pb-2 px-3">
              {/* <div className="modal-header border-0 pb-0">
                    <button type="button" className="close" data-dismiss="modal"><img src={closeicon} width="20px" height="20px"/></button>
                </div> */}
              <div className="container">
                {/* <Form
                onSubmit={this.handlengoPostSubmit}
                ref={(c) => {
                  this.form = c;
                }}> */}

                <div className="row ">
                  <div className="">
                    <div className="row">


                      <div className="col-12  pb-2 pt-3">
                        <h3 className="display-center">
                          Your account has been deactivated.
                        </h3>
                      </div>

                      <div className="col-12  pb-2 pt-3">

                        <div className="display-center">
                          <button
                            className="btn btn-primary ok-btn"
                            //data-dismiss="modal"
                            onClick={this.LogOut}
                          >
                            Ok
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Logoutin2hr"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm ">
            <div className="modal-content pt-0 pb-2 px-3">
              {/* <div className="modal-header border-0 pb-0">
                    <button type="button" className="close" data-dismiss="modal"><img src={closeicon} width="20px" height="20px"/></button>
                </div> */}
              <div className="container">
                {/* <Form
                onSubmit={this.handlengoPostSubmit}
                ref={(c) => {
                  this.form = c;
                }}> */}

                <div className="row ">
                  <div className="">
                    <div className="row">


                      <div className="col-12  pb-2 pt-3">
                        <h3 className="display-center">
                          Your subscription has been expired.
                        </h3>
                      </div>

                      <div className="col-12  pb-2 pt-3">

                        <div className="display-center">
                          <button
                            className="btn btn-primary ok-btn"
                            //data-dismiss="modal"
                            onClick={this.LogOut}
                          >
                            Ok
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
// App.js
// Displaying App.js.