import axios from "axios";

import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;

class AdminLoginService {
 AdminLogin(user_name, password,firebase_token) {
    const insertData = new FormData();
    insertData.set("username", user_name);
    insertData.set("password", password);
    insertData.set("firebase_token", firebase_token);

    return axios
      .post(API_URL + "api/superadmin/adminlogin", insertData)
      .then((response) => {
        return response.data;
      });
  }
 
  AdminLogout(user_name,firebase_token) {
    const insertData = new FormData();
    insertData.set("username", user_name);
   
    insertData.set("firebase_token", firebase_token);

    return axios
      .post(API_URL + "api/superadmin/logout", insertData)
      .then((response) => {
        return response.data;
      });
  }


  AdminChangePass(user_name,password,old_password) {
    const insertData = new FormData();
    insertData.set("username", user_name);
    insertData.set("password", password);
   
    insertData.set("old_password", old_password);
    return axios
      .post(API_URL + "/api/superadmin/changePassword", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AdminInfo() {
    const insertData = new FormData();
    insertData.set("id",1);
  

    return axios
      .post(API_URL + "api/superadmin/Info", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }


}




  export default new AdminLoginService();
