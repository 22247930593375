import { DATA_SUCCESS, DATA_FAIL, SET_MESSAGE } from "./types";

import AdminLoginService from "../services/AdminLogin.Service";

export const AdminLogin =
  (
    user_name, password,firebase_token
  ) =>
  (dispatch) => {
    
    return AdminLoginService.AdminLogin(
        user_name, password,firebase_token
    ).then(
      (response) => {
        dispatch({
          type: DATA_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: DATA_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  export const AdminLogout =
  (
    user_name,firebase_token
  ) =>
  (dispatch) => {
    
    return AdminLoginService.AdminLogout(
        user_name,firebase_token
    ).then(
      (response) => {
        dispatch({
          type: DATA_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: DATA_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
  
export const AdminChangePass =
(
  user_name,password,old_password
) =>
(dispatch) => {
  
  return AdminLoginService.AdminChangePass(
    user_name,password,old_password
  ).then(
    (response) => {
      dispatch({
        type: DATA_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const AdminInfo =
(
  
) =>
(dispatch) => {
  
  return AdminLoginService.AdminInfo(
    
  ).then(
    (response) => {
      dispatch({
        type: DATA_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};